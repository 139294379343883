
.goods-shoot-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    .goods-shoot-header {
        display: flex;
        justify-content: space-between;
        /*align-items: center;*/
        /*height: 58px;*/
        border-bottom: 2px solid #F1F5FF;
        span {
            color: #333;
            font-size: 16px;
        }
    }
}
.task-table {
    border: 1px solid #E5E5E5;
    font-size: 16px;
    .table-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        line-height: 60px;
        background: #F6F6F6;
        border-bottom: 1px solid #EEEEEE;
        box-sizing: border-box;
        padding: 0 40px;
        color: #666;
    }
    .upload {
        display: flex !important;
        align-items: center;
    }
    .text {
        width: 1%;
        flex: 1;
    }
    .table-body {
        display: flex;
        justify-content: space-between;
        height: 70px;
        line-height: 70px;
        padding: 0 40px;
    }
}
.file-upload-btn {
    display: none;
}
.upload-text {
    display: inline-block;
    flex: 1;
    width: 1%;
    color: #666;
    margin-left: 10px;
}
.is-plain {
    background: #FFF;
    border-color: #1CB064;
    color: #1CB064;
    border-radius: 2px;
}
.download-materials {
    color: #2461EF;
    cursor: pointer;
}
